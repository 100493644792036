import {
  TSkill,
  SkillsDrawer as BaseSkillsDrawer,
  SkillsDrawerProps as BaseSkillsDrawerProps,
  TCollection,
  SKILLS_DRAWER_ID,
  useDrawerProps,
  useModalContext,
  TCategory,
} from 'src/design-system'
import * as React from 'react'
import { SkillsService } from '../../services/skills-service'
import { useApiClient } from '../../utils/use-api-client'
import { useRailsContext } from '../rails-context'
import { useDrawerSkills } from '../../utils/use-drawer-skills'
import { trackEvent } from '../../services/event-tracker'
import { FrameworkSkillsDrawerProps } from './types'
import { SKILL_MODAL_ID, SkillModalProps } from 'components/skill-modal/utils'
import { store } from 'store/index'

export type SkillsDrawerProps = {
  source: 'library' | 'org'
  title?: string
  frameworkId?: number | string
  frameworkSkills?: TSkill[]
  refreshProps(skillId?: string): void
  defaultOpen?: boolean
  onClickCollection?(collection: TCollection): void
  libraryAuthors?: { id: number; name: string }[]
  libraryTags?: { id: number; name: string }[]
}

export const SkillsDrawer: React.VFC<SkillsDrawerProps> = (props) => {
  const {
    onClickCollection,
    title,
    frameworkId,
    refreshProps,
    defaultOpen,
    frameworkSkills,
    source: skillSource,
  } = props

  const client = useApiClient()
  const { org } = useRailsContext()
  const service = React.useMemo(
    () => new SkillsService(client, org ?? undefined),
    [client, org]
  )
  const { category } =
    useDrawerProps<FrameworkSkillsDrawerProps>(SKILLS_DRAWER_ID)

  const source = `${skillSource}-skills-drawer`

  const {
    addCollection,
    collections,
    createNewSkill,
    onOpen,
    onSearchChange,
    searchActive,
    skills,
  } = useDrawerSkills(skillSource)

  const { openModal } = useModalContext()

  const onAddSkill = React.useCallback(
    async (
      skill: TSkill,
      _categoryParam?: TCategory,
      skillVariantId?: string
    ) => {
      /**
       * If we're on the org page, the only skills showing are library skills, and we want to
       * add these straight to the org
       */
      if (skillSource === 'library') {
        await service.addToOrg(skill, source)
        trackEvent('$track_add_skill_to_team', {
          source: 'skills-drawer',
          skillType: skillSource,
          skillName: skill.name,
        })
      } else {
        /**
         * If we're on the team page, we want to add the skill directly to the team, either
         * by cloning the library skill, or adding a relation to the org skill
         */
        const variant =
          skillVariantId || (skill.skillVariants && skill.skillVariants[0]?.id)

        if (!variant || !frameworkId) return

        const oldFrameworksSkill = store.frameworksSkills.forFrameworkAndSkill(
          frameworkId.toString(),
          skill.id.toString()
        )

        await service.addToTeam(
          variant.toString(),
          frameworkId,
          source,
          category?.id
        )

        if (oldFrameworksSkill) {
          store.frameworksSkills.unload(oldFrameworksSkill?.id)
        }

        trackEvent('$track_add_skill_to_team', {
          source: 'skills-drawer',
          skillType: skill.cloneable ? 'library' : 'org',
          skillName: skill.name,
        })
      }

      refreshProps()
    },
    [service, refreshProps, skillSource, frameworkId, source, category]
  )

  const onAddCollection = React.useCallback(
    async (collection: TCollection, categoryId?: string) => {
      await addCollection(collection, source, frameworkId, categoryId)
      refreshProps()
    },
    [addCollection, refreshProps, frameworkId, source]
  )

  const onCreateNewSkill = React.useCallback(
    async (name: string) => {
      const newSkill = await createNewSkill({
        name,
        frameworkId,
        categoryId: category?.id.toString(),
      })
      refreshProps(newSkill?.id?.toString())

      if (newSkill?.id) {
        openModal<SkillModalProps>(SKILL_MODAL_ID, {
          skillId: newSkill?.id.toString(),
        })
      }
    },
    [frameworkId, category, createNewSkill, refreshProps, openModal]
  )

  const onClickSkill = React.useCallback(
    (skill: TSkill, skillVariantId?: string) => {
      openModal<SkillModalProps>(SKILL_MODAL_ID, {
        frameworkId: frameworkId?.toString(),
        orgId: org?.id?.toString(),
        showMakeACopy: false,
        skillId: skill.id.toString(),
        skillVariantId: skillVariantId,
        source: 'skills_drawer',
      })
    },
    [frameworkId, openModal, org?.id]
  )

  const eventHandlers: BaseSkillsDrawerProps['eventHandlers'] = {
    onCreateNewSkill,
    onClickSkill,
    onClickCollection,
    onSearchChange,
    onAddSkill,
    onAddCollection,
  }

  const orgName = org?.name
  const frameworkName = store.frameworks.byId(frameworkId?.toString())?.name

  return (
    <BaseSkillsDrawer
      orgName={orgName}
      frameworkName={frameworkName}
      eventHandlers={eventHandlers}
      title={title}
      defaultOpen={defaultOpen}
      skills={skills.skills}
      showLoadingSpinner={skills?.loading}
      frameworkSkills={frameworkSkills ?? []}
      collections={collections?.collections}
      onOpen={onOpen}
      skillGeneratorUrl="/skills/generate/new"
      orgSkillsUrl={`${store.currentUser?.org?.skillsPath}?open_drawer=true`}
      source={skillSource}
      showAiSkillGenerator={false}
      overlay={false}
      showAddedSkills={searchActive}
    />
  )
}
