import * as React from 'react'
import { TCollection, TSkill, useModalContext } from 'src/design-system'
import { useApiClient } from './use-api-client'
import { useRailsContext } from 'components/rails-context'
import { SkillsService } from '../services/skills-service'
import { openModal as openRailsModal } from './open-modal'
import { store } from 'store/index'
import { SkillModalProps, SKILL_MODAL_ID } from 'components/skill-modal/utils'
import { successToast } from './success-toast'
import { errorToast } from './error-toast'
import { SearchObject } from '../design-system/components/molecules/Search'

type SkillData = {
  skills: TSkill[]
  count: number
  loading: boolean
}

type CollectionData = {
  collections: TCollection[]
  count: number
}

export const useDrawerSkills = (source: 'library' | 'org' | 'all') => {
  const client = useApiClient()
  const { org } = useRailsContext()
  const skillsService = React.useMemo(
    () => new SkillsService(client, org ?? undefined),
    [client, org]
  )
  const { openModal } = useModalContext()

  const [skills, setSkills] = React.useState<SkillData>({
    skills: [],
    count: 0,
    loading: true,
  })
  const [collections, setCollections] = React.useState<CollectionData>({
    collections: [],
    count: 0,
  })
  const [searchObject, setSearchObject] = React.useState<SearchObject | null>(
    null
  )

  const searchActive = React.useMemo(
    () => !!searchObject?.query,
    [searchObject]
  )

  const fetchSkills = React.useCallback(
    async (query?: string) => {
      try {
        const [skills, count] = await skillsService.getSkills(source, query)

        setSkills({ count, loading: false, skills })
      } catch (e) {
        console.error(e)
      }
    },
    [skillsService, source]
  )

  React.useEffect(() => {
    const fetchSkillsWithQuery = () => {
      fetchSkills(searchObject?.query)
    }

    document.addEventListener('refreshSkillsDrawerSkills', fetchSkillsWithQuery)

    return () => {
      document.removeEventListener(
        'refreshSkillsDrawerSkills',
        fetchSkillsWithQuery
      )
    }
  }, [searchObject?.query, fetchSkills])

  const fetchCollections = React.useCallback(
    async (query?: string, filters?: Record<string, unknown>) => {
      try {
        const [collections, count] = await skillsService.getCollections(
          query,
          filters
        )

        setCollections({ collections, count })
      } catch (e) {
        console.error(e)
      }
    },
    [skillsService]
  )

  const createNewSkill = React.useCallback(
    async ({
      name,
      frameworkId,
      categoryId,
    }: {
      name: string
      teamId?: number | string
      frameworkId?: number | string
      categoryId?: string
    }) => {
      const { skills, frameworksSkills } = store
      const response = await skills.create(
        { name },
        { include: ['skill_variants'] }
      )

      if (!response.success) {
        errorToast('Something went wrong')
        return
      }

      const newSkillId = Object.keys(response.data?.skills || {})[0]
      const newSkill = skills.byId(newSkillId)
      const skillVariant = newSkill?.defaultVariant

      if (newSkill && frameworkId && skillVariant) {
        const frameworkSkillsResponse = await frameworksSkills.create(
          {
            category: categoryId?.toString(),
            skillVariant: skillVariant.id,
            framework: frameworkId.toString(),
          },
          { include: ['skill'] }
        )

        if (frameworkSkillsResponse.success) {
          successToast('Skill added to the team')
          document.dispatchEvent(new Event('frameworkpage:updated'))

          openModal<SkillModalProps>(SKILL_MODAL_ID, {
            skillId: newSkill.id.toString(),
            frameworkId: frameworkId.toString(),
            orgId: org?.id?.toString(),
          })
        } else {
          errorToast('Something went wrong')
        }
      }

      return newSkill
    },
    [openModal, org?.id]
  )

  const addCollection = async (
    collection: TCollection,
    source: string,
    frameworkId?: number | string,
    categoryId?: string
  ) => {
    if (frameworkId) {
      await skillsService.addCollection(
        collection,
        frameworkId,
        source,
        categoryId
      )
    } else {
      openRailsModal(`/collections/${collection.id}/add_to_team_modal`)
    }
  }

  const onSearchChange = React.useCallback(
    async (search) => {
      const { query, filters } = search

      if (search) setSearchObject(search)

      fetchSkills(query)
      fetchCollections(query, filters)
    },
    [fetchSkills, fetchCollections]
  )

  const onOpen = React.useCallback(async () => {
    await Promise.all([fetchSkills(), fetchCollections()])
  }, [fetchSkills, fetchCollections])

  const onClickSkill = React.useCallback(
    (skill: TSkill) => {
      openModal<SkillModalProps>(SKILL_MODAL_ID, {
        skillId: skill.id.toString(),
        orgId: org?.id?.toString(),
        showMakeACopy: false,
      })
    },
    [openModal, org?.id]
  )

  const onClickCollection = React.useCallback((collection: TCollection) => {
    openRailsModal(`/collections/${collection.id}/view_modal`)
  }, [])

  return {
    addCollection,
    collections,
    createNewSkill,
    fetchCollections,
    fetchSkills,
    onClickCollection,
    onClickSkill,
    onOpen,
    onSearchChange,
    searchActive,
    searchObject,
    skills,
  }
}
