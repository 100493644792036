import {
  TCollection,
  TSkillWithOrg,
  TSkill,
  TSkillVariant,
} from 'src/design-system'
import { deserialize } from '../api/utils/deprecated_deserialize'
import { Org } from '../types/entities'
import { InternalApiClient } from './internal-api-client'
import { errorToast } from '../utils/error-toast'
import { successToast } from '../utils/success-toast'
import { store } from 'store/index'

export type SkillSortValues = 'name' | '-name' | 'created_at' | '-created_at'

export class SkillsService {
  constructor(private client: InternalApiClient, private org?: Org) {}

  async getSkills(
    source: 'library' | 'org' | 'all',
    query?: string,
    sort?: SkillSortValues,
    filters?: Record<string, unknown>
  ): Promise<[skills: TSkillWithOrg[], count: number]> {
    let url = '/api/v1/internal/skills'
    const searchParams = new URLSearchParams()

    if (source !== 'all') {
      searchParams.append('filter[source]', source)
    }

    if (sort) searchParams.append('sort', sort)
    if (query) searchParams.append('query', query)

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (value === '') return
        let parsedValue: string

        if (typeof value === 'string') {
          parsedValue = value
        } else if (typeof value === 'number') {
          parsedValue = value.toString()
        } else if (Array.isArray(value)) {
          parsedValue = value.join(',')
        } else {
          throw new Error(`Unsupported filter value type: ${typeof value}`)
        }

        searchParams.append(`filter[${key}]`, parsedValue)
      })
    }

    url += `?${searchParams}`

    const raw = await this.client.get(url)
    const { data, meta } = deserialize(raw)

    return [data, meta.total]
  }

  async getCollections(
    query?: string,
    filters?: Record<string, unknown>,
    sort?: 'name' | '-name' | 'created_at' | '-created_at'
  ): Promise<[collections: TCollection[], count: number]> {
    let url = `/api/v1/internal/collections`
    if (sort || query || filters) {
      const searchParams = new URLSearchParams()
      if (sort) searchParams.append('sort', sort)
      if (query) searchParams.append('query', query)
      if (filters) {
        Object.entries(filters).forEach(([key, value]) => {
          if (value === '') return
          let parsedValue: string

          if (typeof value === 'string') {
            parsedValue = value
          } else if (typeof value === 'number') {
            parsedValue = value.toString()
          } else if (Array.isArray(value)) {
            parsedValue = value.join(',')
          } else {
            throw new Error(`Unsupported filter value type: ${typeof value}`)
          }

          if (key === 'display_org_id') {
            searchParams.append('filter[skills.display_org_id]', parsedValue)
          } else {
            searchParams.append(`filter[${key}]`, parsedValue)
          }
        })
      }
      url += `?${searchParams}`
    }

    const raw = await this.client.get(url)
    const { data, meta } = deserialize(raw)

    return [data, meta.total]
  }

  async create({
    name,
    teamId,
    frameworkId,
    categoryId,
  }: {
    name: string
    teamId?: number | string
    frameworkId?: number | string
    categoryId?: string
  }): Promise<TSkill | null> {
    if (!this.org) return null

    const raw = await this.client.post('/skills', {
      skill: {
        name,
        team_id: teamId,
        framework_id: frameworkId,
        authoring_org_id: this.org.id,
        category_id: categoryId,
      },
    })
    return deserialize(raw).data
  }

  async addToTeam(
    skillVariantId: string,
    frameworkId: number | string,
    source?: string,
    categoryId?: string | number | undefined
  ): Promise<string | undefined> {
    try {
      const { frameworksSkills } = store

      const result = await frameworksSkills.create(
        {
          framework: frameworkId.toString(),
          skillVariant: skillVariantId,
          category: categoryId?.toString(),
        },
        { source, include: ['skill'] }
      )

      if (result.success) {
        successToast('Skill added to framework')
      } else {
        errorToast()
      }
    } catch (e) {
      return 'Something went wrong, please try again.'
    }
  }

  async addToOrg(skill: TSkill, source?: string): Promise<string | undefined> {
    try {
      const result = await this.client.post<{ notice: string }>(
        `/skills/${skill.id}/make_a_copy`,
        {
          org_id: this.org?.id,
          id: skill.id,
          source: source,
        }
      )
      successToast(result.notice)
    } catch (e) {
      return 'Something went wrong, please try again.'
    }
  }

  async addCollection(
    collection: TCollection,
    frameworkId?: number | string,
    source?: string,
    categoryId?: string | number | null
  ): Promise<string | undefined> {
    try {
      const result = await this.client.put<{ notice: string }>(
        `/collections/${collection.id}/add_to_team`,
        {
          add_to_team: {
            target_framework: frameworkId,
            category_id: categoryId,
          },
          // used for tracking where the collection was added from
          source: source,
        }
      )
      successToast(result.notice)
    } catch (e) {
      return 'Something went wrong, please try again.'
    }
  }

  async removeFromTeam(
    skill: TSkill,
    frameworkId: number,
    source: string
  ): Promise<void> {
    try {
      const result = await this.client.put<{ notice: string }>(
        `/frameworks/${frameworkId}/remove_skill`,
        {
          redirect_path: location.pathname,
          skill_id: skill.id,
          source: source,
        }
      )
      successToast(result.notice)
    } catch (e) {
      console.log(e)
      errorToast()
    }
  }

  async removeVariantFromTeam(
    skillVariant: TSkillVariant,
    frameworkId: number,
    source: string
  ): Promise<void> {
    try {
      const result = await this.client.put<{ notice: string }>(
        `/frameworks/${frameworkId}/remove_skill_variant`,
        {
          redirect_path: location.pathname,
          skill_variant_id: skillVariant.id,
          source: source,
        }
      )
      successToast(result.notice)
    } catch (e) {
      console.log(e)
      errorToast()
    }
  }
}
